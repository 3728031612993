import HeaderMenu from './HeaderMenu'
import { useSelector } from 'react-redux'

function Header() {
    const { user } = useSelector((state) => state.auth)

    return (
        <>
            {user ? (
                <header className='header'>
                    <HeaderMenu />
                </header>
            ) : (
                <></>
            )}
        </>
    )
}

export default Header
