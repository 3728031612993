import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import courtReducer from '../features/courts/courtSlice';
import userReducer from '../features/users/userSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		courts: courtReducer,
		users: userReducer,
	},
});
