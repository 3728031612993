import { Link, useLocation } from 'react-router-dom'

function HeaderMenu() {
    const location = useLocation()

    const isCurrentRoute = (route) => {
        if(route === location.pathname) {
            return true
        }
    }
	  
    return (          
        <ul className='headerMenu'>            
            <li className={'headerMenuItem ' + ((isCurrentRoute('/overview')) && 'active')}>
                <Link to='/overview'>Overview</Link>
            </li>
            <li className={'headerMenuItem ' + ((isCurrentRoute('/courts')) && 'active')}>
                <Link to='/courts'>Courts</Link>
            </li>
            <li className={'headerMenuItem ' + ((isCurrentRoute('/users')) && 'active')}>
                <Link to='/users'>Users</Link>
            </li>
            <li className='headerMenuItem'>
                <Link to='/logout'>Logout</Link>
            </li>     
        </ul>
    )
  }
  
  export default HeaderMenu