import axios from 'axios'

const getCourts = async(data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post('/api/getCourts', data, config)

    return response.data
}

const getCourt = async(courtId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get('/api/getCourt?court=' + courtId, config)

    return response.data
}

const createCourt = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
  
    const response = await axios.post('/api/createCourt', data, config)
  
    return response.data
}

const updateCourt = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
  
    const response = await axios.post('/api/updateCourt', data, config)
  
    return response.data
}

const deleteCourt = async(courtId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get('/api/deleteCourt?court=' + courtId, config)

    return response.data
}

const courtService = {
    getCourts,
    getCourt,
    createCourt,
    updateCourt,
    deleteCourt,
}

export default courtService