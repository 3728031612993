import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Header from './components/Header'
import Overview from './pages/Overview'
import Courts from './pages/Courts'
import CreateCourt from './pages/CreateCourt'
import UpdateCourt from './pages/UpdateCourt'
import Users from './pages/Users'
import CreateUser from './pages/CreateUser'
import UpdateUser from './pages/UpdateUser'

function App() {
	return (
		<>
			<Router>
				<Header />
				<main className='bodyWrapper'>
					<div className='container'>
						<Routes>
							<Route path='/' element={<Login/>} />
							<Route
								path='/logout'
								element={
									<PrivateRoute>
										<Logout />
									</PrivateRoute>
								}
							/>
							<Route
								path='/overview'
								element={
									<PrivateRoute>
										<Overview />
									</PrivateRoute>
								}
							/>
							<Route
								path='/courts'
								element={
									<PrivateRoute>
										<Courts />
									</PrivateRoute>
								}
							/>
							<Route
								path='/create-court'
								element={
									<PrivateRoute>
										<CreateCourt />
									</PrivateRoute>
								}
							/>
							<Route
								path='/update-court/:courtId'
								element={
									<PrivateRoute>
										<UpdateCourt />
									</PrivateRoute>
								}
							/>
							<Route
								path='/users'
								element={
									<PrivateRoute>
										<Users />
									</PrivateRoute>
								}
							/>
							<Route
								path='/create-user'
								element={
									<PrivateRoute>
										<CreateUser />
									</PrivateRoute>
								}
							/>
							<Route
								path='/update-user/:userId'
								element={
									<PrivateRoute>
										<UpdateUser />
									</PrivateRoute>
								}
							/>
						</Routes>
					</div>
				</main>
			</Router>
			<ToastContainer />
		</>
	);
}

export default App;
