import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaRegEdit, FaTrashAlt, FaEnvelope, FaPhone, FaFax, FaCaretUp, FaCaretDown } from 'react-icons/fa'
import { getCourts, deleteCourt } from '../features/courts/courtSlice'

function Courts() {
    const dispatch = useDispatch()

    const [filterData, setFilterData] = useState({
        filterBy: 'name',
        query: '',
    })

    const [sortData, setSortData] = useState({
        orderBy: 'name',
        order: 'asc',
    })

    const {
		courts, 
	} = useSelector(state => state.courts)

    useEffect(() => {
        dispatch(getCourts({
            sort: sortData,
            filter: filterData,
        })) 
    }, [dispatch, sortData, filterData])

    const onSort = async (orderBy, order) => {
        setSortData({
            orderBy,
            order,
        })
    }

    const onFilter = async (filterBy, query) => {
        setFilterData({
            filterBy,
            query,
        })
    }

    const onDelete = async (courtId) => {
        dispatch(deleteCourt(courtId))
        dispatch(getCourts()) 
        toast.success('Deleted')
    }

    if (!courts) return <></>

    return (
        <>
            <Link to='/create-court' className='btn btn-info' style={{float: 'right'}}>Create Court</Link>
            
            <h1 className="pageTitle">Courts</h1>

            <table className='table'>
                <thead>
                    <tr>
                        <th colSpan='9'>
                            <input 
                                type='text' 
                                id='query' 
                                className='form-control' 
                                value={filterData.query} 
                                onChange={(e) => onFilter('name', e.target.value)} 
                                placeholder='Filter...'
                                style={{
                                    width: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                }}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th className='sort-column' onClick={() => onSort('name', ((sortData.order === 'desc') ? 'asc' : 'desc') )}>
                            Name
                            {(sortData.orderBy === 'name' && sortData.order === 'asc') && (
                                <FaCaretUp color='black' />
                            )}
                            {(sortData.orderBy === 'name' && sortData.order === 'desc') && (
                                <FaCaretDown color='black' />
                            )}
                        </th>
                        <th>Address</th>
                        <th className='sort-column' onClick={() => onSort('suburb', ((sortData.order === 'desc') ? 'asc' : 'desc') )}>
                            Suburb
                            {(sortData.orderBy === 'suburb' && sortData.order === 'asc') && (
                                <FaCaretUp color='black' />
                            )}
                            {(sortData.orderBy === 'suburb' && sortData.order === 'desc') && (
                                <FaCaretDown color='black' />
                            )}
                        </th>
                        <th className='sort-column' onClick={() => onSort('postcode', ((sortData.order === 'desc') ? 'asc' : 'desc') )}>
                            Postcode
                            {(sortData.orderBy === 'postcode' && sortData.order === 'asc') && (
                                <FaCaretUp color='black' />
                            )}
                            {(sortData.orderBy === 'postcode' && sortData.order === 'desc') && (
                                <FaCaretDown color='black' />
                            )}
                        </th>
                        <th className='sort-column' onClick={() => onSort('state', ((sortData.order === 'desc') ? 'asc' : 'desc') )}>
                            State
                            {(sortData.orderBy === 'state' && sortData.order === 'asc') && (
                                <FaCaretUp color='black' />
                            )}
                            {(sortData.orderBy === 'state' && sortData.order === 'desc') && (
                                <FaCaretDown color='black' />
                            )}
                        </th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Fax</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {courts.map((court, index) => {
                        const {id, name, address, suburb, state, postcode, email, phone, fax} = court

                        return (
                            <tr key={index}>
                                <td style={{width: 250}}>{name}</td>
                                <td>{address}</td>
                                <td style={{width: 150}}>{suburb}</td>
                                <td style={{width: 110}}>{postcode}</td>
                                <td style={{width: 80}}>{state}</td>
                                <td style={{textAlign: 'center'}}>
                                    {(email) && (
                                        <a href={'mailto:' + email}>
                                            <FaEnvelope color='black' />
                                        </a>
                                    )}
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    {(phone) && (
                                        <a href={'tel:' + phone}>
                                            <FaPhone color='black' />
                                        </a>
                                    )}
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    {(fax) && (
                                        <a href={'tel:' + fax}>
                                            <FaFax color='black' />
                                        </a>
                                    )}
                                </td>
                                <td style={{width: 60, textAlign: 'center'}}>
                                    <Link to={'/update-court/' + id} className='btn btn-info'>
                                        <FaRegEdit />
                                    </Link>
                                    <button onClick={() => onDelete(id)} className='btn btn-danger'>
                                        <FaTrashAlt /> 
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                 
                </tbody>
            </table>
            
        </>
        
    )
}

export default Courts
