import axios from 'axios'

const getUsers = async(data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.post('/api/getUsers', data, config)

    return response.data
}

const getUser = async(userId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get('/api/getUser?user=' + userId, config)

    return response.data
}

const createUser = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
  
    const response = await axios.post('/api/createUser', data, config)
  
    return response.data
}

const updateUser = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
  
    const response = await axios.post('/api/updateUser', data, config)
  
    return response.data
}

const deleteUser = async(userId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get('/api/deleteUser?user=' + userId, config)

    return response.data
}

const userService = {
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
}

export default userService