import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getUser, updateUser } from '../features/users/userSlice'

function UpdateUser() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const [formData, setFormData] = useState({
        type: '',
        status: '',
        email: '',
        newPassword: '',
        firstname: '',
        lastname: '',
        phone: '',
        company: '',
        business_address: '',
        business_suburb: '',
        business_postcode: '',
        business_state: '',
        postal_check: '',
        postal_address: '',
        postal_suburb: '',
        postal_postcode: '',
        postal_state: '',
    })

    const {
        type,
        status,
        email,
        newPassword,
        firstname,
        lastname,
        phone,
        company,
        business_address,
        business_suburb,
        business_postcode,
        business_state,
        postal_check,
        postal_address,
        postal_suburb,
        postal_postcode,
        postal_state
    } = formData

    const roles = [
        {
            label: 'Admin',
            value: 'admin'
        },
        {
            label: 'User',
            value: 'user'
        }
    ]

    const statuses = [
        {
            label: 'Active',
            value: '1'
        },
        {
            label: 'Disabled',
            value: '0'
        }
    ]

    const states = [
        'NSW',
        'VIC'
    ]

    const {
		user, 
	} = useSelector(state => state.users)

    useEffect(() => {
        dispatch(getUser(params.userId)) 
    }, [params.userId, dispatch, navigate])

    useEffect(() => {
        if(user) {
            setFormData(user)
        }
    }, [params.userId, user])

    const onSubmit = async (e) => {
        e.preventDefault()

        dispatch(updateUser(formData))
        toast.success('Updated')
        navigate('/users')
    }

    const onMutate = (e) => {
        let boolean = null

        if(e.target.value === 'true') {
            boolean = true
        }

        if(e.target.value === 'false') {
            boolean = false
        }

        if(e.target.id === 'postal_check') {
            boolean = e.target.checked
        }

        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: boolean ?? e.target.value,
        }))
    }

    if (!user) return <></>

    return (
        <>
            <h1 className="pageTitle">Update User</h1>

            <form className='form' onSubmit={onSubmit}>

                <div className='form-group'>
                    <label className='form-label'>Type</label>
                    <select
                        className='form-control'
                        id='type'
                        value={type}
                        onChange={onMutate}
                        required
                    >
                        {roles.map((role, index) => (
                            <option key={index} value={role.value}>{role.label}</option>
                        ))}
                    </select>
                </div>

                <div className='form-group'>
                    <label className='form-label'>Status</label>
                    <select
                        className='form-control'
                        id='status'
                        value={status}
                        onChange={onMutate}
                        required
                    >
                        {statuses.map((status, index) => (
                            <option key={index} value={status.value}>{status.label}</option>
                        ))}
                    </select>
                </div>

                <div className='form-group'>
                    <label className='form-label'>Email</label>
                    <input
                        className='form-control'
                        type='email'
                        id='email'
                        value={email}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>New Password</label>
                    <input
                        className='form-control'
                        type='password'
                        id='newPassword'
                        value={newPassword}
                        onChange={onMutate}
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>First Name</label>
                    <input
                        className='form-control'
                        type='text'
                        id='firstname'
                        value={firstname}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Last Name</label>
                    <input
                        className='form-control'
                        type='text'
                        id='lastname'
                        value={lastname}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Phone</label>
                    <input
                        className='form-control'
                        type='text'
                        id='phone'
                        value={phone}
                        onChange={onMutate}
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Company</label>
                    <input
                        className='form-control'
                        type='text'
                        id='company'
                        value={company}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Business Address</label>
                    <input
                        className='form-control'
                        type='text'
                        id='business_address'
                        value={business_address}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Business Suburb</label>
                    <input
                        className='form-control'
                        type='text'
                        id='business_suburb'
                        value={business_suburb}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Business State</label>
                    <select
                        className='form-control'
                        id='business_state'
                        value={business_state}
                        onChange={onMutate}
                        required
                    >
                        {states.map((state, index) => (
                            <option key={index} value={state}>{state}</option>
                        ))}
                    </select>
                </div>

                <div className='form-group'>
                    <label className='form-label'>Business Postcode</label>
                    <input
                        className='form-control'
                        type='text'
                        id='business_postcode'
                        value={business_postcode}
                        onChange={onMutate}
                        maxLength='4'
                        minLength='4'
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Has Postal Address</label>
                    <input
                        className='form-checkbox'
                        type='checkbox'
                        id='postal_check'
                        value={postal_check}
                        onChange={onMutate}
                    />
                </div>

                {(postal_check) && (
                    <>
                        <div className='form-group'>
                            <label className='form-label'>Postal Address</label>
                            <input
                                className='form-control'
                                type='text'
                                id='postal_address'
                                value={postal_address}
                                onChange={onMutate}
                                required
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Postal Suburb</label>
                            <input
                                className='form-control'
                                type='text'
                                id='postal_suburb'
                                value={postal_suburb}
                                onChange={onMutate}
                                required
                            />
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Postal State</label>
                            <select
                                className='form-control'
                                id='postal_state'
                                value={postal_state}
                                onChange={onMutate}
                                required
                            >
                                {states.map((state, index) => (
                                    <option key={index} value={state}>{state}</option>
                                ))}
                            </select>
                        </div>

                        <div className='form-group'>
                            <label className='form-label'>Postal Postcode</label>
                            <input
                                className='form-control'
                                type='text'
                                id='postal_postcode'
                                value={postal_postcode}
                                onChange={onMutate}
                                maxLength='4'
                                minLength='4'
                                required
                            />
                        </div>
                    </>
                )}

                <button type='submit' className='btn btn-info'>
                    Update
                </button>
            </form>
        </>
        
    )
}

export default UpdateUser
