import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createCourt } from '../features/courts/courtSlice'

function CreateCourt() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        suburb: '',
        state: '',
        postcode: '',
        email: '',
        phone: '',
        fax: '',
        // name: 'Test Court',
        // address: '100 Some St',
        // suburb: 'Melbourne',
        // state: 'VIC',
        // postcode: '1234',
        // email: 'someone@somewhere.com',
        // phone: '042123456789',
        // fax: '042123456789',
    })

    const {
        name, 
        address, 
        suburb, 
        state, 
        postcode, 
        email, 
        phone,
        fax,
    } = formData

    const states = [
        'NSW',
        'VIC'
    ]

    const onSubmit = async (e) => {
        e.preventDefault()

        dispatch(createCourt(formData))
        toast.success('Created')
        navigate('/courts')
    }

    const onMutate = (e) => {
        let boolean = null

        if(e.target.value === 'true') {
            boolean = true
        }

        if(e.target.value === 'false') {
            boolean = false
        }

        setFormData((prevState) => ({
            ...prevState,
            [e.target.id]: boolean ?? e.target.value,
        }))
    }

    return (
        <>
            <h1 className="pageTitle">Create Court</h1>

            <form className='form' onSubmit={onSubmit}>
                <div className='form-group'>
                    <label className='form-label'>Name</label>
                    <input
                        className='form-control'
                        type='text'
                        id='name'
                        value={name}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Address</label>
                    <input
                        className='form-control'
                        type='text'
                        id='address'
                        value={address}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Suburb</label>
                    <input
                        className='form-control'
                        type='text'
                        id='suburb'
                        value={suburb}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>State</label>
                    <select
                        className='form-control'
                        id='state'
                        value={state}
                        onChange={onMutate}
                        required
                    >
                        {states.map((state, index) => (
                            <option key={index} value={state}>{state}</option>
                        ))}
                    </select>
                </div>

                <div className='form-group'>
                    <label className='form-label'>Postcode</label>
                    <input
                        className='form-control'
                        type='text'
                        id='postcode'
                        value={postcode}
                        onChange={onMutate}
                        maxLength='4'
                        minLength='4'
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Email</label>
                    <input
                        className='form-control'
                        type='email'
                        id='email'
                        value={email}
                        onChange={onMutate}
                        required
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Phone</label>
                    <input
                        className='form-control'
                        type='text'
                        id='phone'
                        value={phone}
                        onChange={onMutate}
                    />
                </div>

                <div className='form-group'>
                    <label className='form-label'>Fax</label>
                    <input
                        className='form-control'
                        type='text'
                        id='fax'
                        value={fax}
                        onChange={onMutate}
                    />
                </div>

                <button type='submit' className='btn btn-info'>
                    Create
                </button>
            </form>
        </>
        
    )
}

export default CreateCourt
