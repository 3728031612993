import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import courtService from './courtService'

const initialState = {
    courts: [],
    court: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const getCourts = createAsyncThunk('courts/getCourts', async(data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await courtService.getCourts(data, token)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const getCourt = createAsyncThunk('courts/getCourt', async(courtId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await courtService.getCourt(courtId, token)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const createCourt = createAsyncThunk('courts/createCourt', async(data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await courtService.createCourt(data, token)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const updateCourt = createAsyncThunk('courts/updateCourt', async(data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await courtService.updateCourt(data, token)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const deleteCourt = createAsyncThunk('courts/deleteCourt', async(courtId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await courtService.deleteCourt(courtId, token)
    } catch(error) {
        const message = (error.response && error.response.data && error.response.data.message) || 
        error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})

export const courtSlice = createSlice({
    name: 'court',
    initialState: initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCourts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCourts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.courts = action.payload
            })
            .addCase(getCourts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            
            .addCase(getCourt.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCourt.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.court = action.payload
            })
            .addCase(getCourt.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            
            .addCase(createCourt.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createCourt.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(createCourt.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            
            .addCase(updateCourt.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateCourt.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(updateCourt.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            
            .addCase(deleteCourt.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteCourt.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(deleteCourt.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const {
    reset, 
} = courtSlice.actions

export default courtSlice.reducer