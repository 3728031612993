import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaRegEdit, FaTrashAlt, FaCaretUp, FaCaretDown } from 'react-icons/fa'
import { getUsers, deleteUser } from '../features/users/userSlice'

function Users() {
    const dispatch = useDispatch()

    const [filterData, setFilterData] = useState({
        filterBy: 'firstname',
        query: '',
    })

    const [sortData, setSortData] = useState({
        orderBy: 'firstname',
        order: 'asc',
    })

    const {
		users,
	} = useSelector(state => state.users)

    useEffect(() => {
        dispatch(getUsers({
            sort: sortData,
            filter: filterData,
        }))
    }, [dispatch, sortData, filterData])

    const onSort = async (orderBy, order) => {
        setSortData({
            orderBy,
            order,
        })
    }

    const onFilter = async (filterBy, query) => {
        setFilterData({
            filterBy,
            query,
        })
    }

    const onDelete = async (userId) => {
        dispatch(deleteUser(userId))
        dispatch(getUsers())
        toast.success('Deleted')
    }

    if (!users) return <></>

    return (
        <>
            <Link to='/create-user' className='btn btn-info' style={{float: 'right'}}>Create User</Link>

            <h1 className="pageTitle">Users</h1>

            <table className='table'>
                <thead>
                    <tr>
                        <th colSpan='7'>
                            <input
                                type='text'
                                id='query'
                                className='form-control'
                                value={filterData.query}
                                onChange={(e) => onFilter('firstname', e.target.value)}
                                placeholder='Filter...'
                                style={{
                                    width: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                }}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th className='sort-column' onClick={() => onSort('firstname', ((sortData.order === 'desc') ? 'asc' : 'desc') )}>
                            First Name
                            {(sortData.orderBy === 'firstname' && sortData.order === 'asc') && (
                                <FaCaretUp color='black' />
                            )}
                            {(sortData.orderBy === 'firstname' && sortData.order === 'desc') && (
                                <FaCaretDown color='black' />
                            )}
                        </th>
                        <th className='sort-column' onClick={() => onSort('lastname', ((sortData.order === 'desc') ? 'asc' : 'desc') )}>
                            Last Name
                            {(sortData.orderBy === 'lastname' && sortData.order === 'asc') && (
                                <FaCaretUp color='black' />
                            )}
                            {(sortData.orderBy === 'lastname' && sortData.order === 'desc') && (
                                <FaCaretDown color='black' />
                            )}
                        </th>
                        <th>Company</th>
                        <th>Email</th>
                        <th>Type</th>
                        <th>Expiration</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => {
                        const {
                            id,
                            type,
                            status,
                            email,
                            firstname,
                            lastname,
                            company,
                            paid,
                            joined
                        } = user

                        let expiration = paid;
                        if (paid == '0000-00-00 00:00:00')
                          expiration = 'Trial (' + joined + ')';
                        else {
                          let MySQLDate = paid;
                          let date = MySQLDate.replace( /[-]/g, '/' );
                          date = Date.parse( date );
                          let now = new Date();
                          if (date < now)
                          {
                            expiration = 'Expired (' + paid + ')';
                          }
                        }

                        return (
                            <tr key={index}>
                                <td>{firstname}</td>
                                <td>{lastname}</td>
                                <td>{company}</td>
                                <td>{email}</td>
                                <td style={{width: 100}}>{(type === 'admin') ? 'Admin' : 'User'}</td>
                                <td>{expiration}</td>
                                <td style={{width: 100}}>{(status === '1') ? 'Active' : 'Disabled'}</td>
                                <td style={{width: 60, textAlign: 'center'}}>
                                    <Link to={'/update-user/' + id} className='btn btn-info'>
                                        <FaRegEdit />
                                    </Link>
                                    <button onClick={() => onDelete(id)} className='btn btn-danger'>
                                        <FaTrashAlt />
                                    </button>
                                </td>
                            </tr>
                        )
                    })}

                </tbody>
            </table>

        </>

    )
}

export default Users
