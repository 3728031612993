import { useNavigate } from 'react-router-dom'
import { useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { logout, reset as authReset } from '../features/auth/authSlice'

function Logout() {
  const navigate = useNavigate()
	const dispatch = useDispatch()

    useEffect(() => {
      dispatch(logout())
      dispatch(authReset())
      navigate('/')
      // eslint-disable-next-line
    }, [])
	  
    return (
        <></>
    )
  }
  
  export default Logout